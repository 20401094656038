import { useContext, useEffect, useState } from 'react';
import { calculateTimeLeft } from '../../util/calculateTime';
import { ClockIcon } from '@heroicons/react/solid'
import { PromotionContext } from '../../pages/_app';
import sanitizeHtml from 'sanitize-html';

export const FormBanner = () => {
    let { dateActive, setLoading, loading, promo, setPromo, details } = useContext(PromotionContext);
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(dateActive));
    const [show, setShow] = useState(false)

    useEffect(() => {
            if (promo && !loading) {
                setShow(true)
            } else {
                setShow(false)
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, promo]);

    useEffect(() => {
        if (dateActive != null && !loading) {
            setTimeout(() => setTimeLeft(calculateTimeLeft(dateActive)), 1000);
            const today = Math.floor(new Date().getTime() / 1000);
            if (dateActive < today) {
                setPromo(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeLeft, dateActive]);

    useEffect(() => {
        if (dateActive != null) {
            setTimeLeft(calculateTimeLeft(dateActive))
            setLoading(false)
        } else {
            setLoading(true)
        }
        return () => {
      setTimeLeft({});
    };    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateActive]);

    const DetailsText = () => {
        const dirty = details.textForm != null ? details.textForm : '';
        const clean = sanitizeHtml(dirty, {
            allowedTags: ['b', 'i', 'em', 'strong', 'a', 'del', 'br'],
            allowedAttributes: {
                a: ['href', 'target']
            }
        });
        return (
            <div
                dangerouslySetInnerHTML={{ __html: clean }}
            />
        );
    };

    return (
        <>
            <div className={`bg-[#C43815] rounded-t-2xl w-full items-center flex-col text-center text-white py-3 px-2 gap-2 transition-all ${show ? 'block opacity-100' : 'hidden opacity-0'} duration-300 flex`}>
                <span className='text-xl ssm:text-lg'><DetailsText /></span>
                <div className='flex gap-2 items-center justify-center font-quicksand'>
                    <ClockIcon className="h-5 w-5 ssm:h-4 ssm:w-4 text-white block"></ClockIcon>
                    <span className='text-base ssm:text-sm font-semibold'> La oferta termina en</span>
                    <div className='flex flex-row gap-1 text-base ssm:text-sm font-semibold'>
                        {timeLeft.days > 0 && <div className="flex flex-row items-end">
                            <span className="value">{String(timeLeft.days).padStart(2, '0')}</span>
                            <span className="label font-quicksand">d</span>
                            <span>:</span>
                        </div>}
                        <div className="flex flex-row items-end">
                            <span className="value">{String(timeLeft.hours).padStart(2, '0')}</span>
                            <span className="label font-quicksand">h</span>
                            <span>:</span>
                        </div>
                        <div className="flex flex-row items-end">
                            <span className="value">{String(timeLeft.minutes).padStart(2, '0')}</span>
                            <span className="label font-quicksand">m</span>
                            <span>:</span>
                        </div>
                        <div className="flex flex-row items-end">
                            <span className="value">{String(timeLeft.seconds).padStart(2, '0')}</span>
                            <span className="label font-quicksand">s</span>
                        </div>
                    </div>
                </div>
            </div>
        </>


    );
};
export default FormBanner;