import Image from "next/image";
const BadgePromo = ({ text, src }) => {
    const textObject = text == undefined ? {text: 'Conoce tu estatus fiscal a sólo', textBold: '¡Últimas horas!', price: '589'} : JSON.parse(text)
    return (
        <>
            <div className="relative flex flex-row w-[500px] mmd:left-0 -left-12 ssm:left-0 ssm:w-full items-center justify-end">
                <div className="z-[1] absolute -left-2 -top-6 ssm:-left-3">
                    <Image
                        loading="lazy"
                        width={'90px'}
                        height={'90px'}
                        alt="fixi-promoción"
                        src="https://cdn.fixat.mx/assets/home/img/fixipromo.png"
                    />
                </div>
                <div className={`w-[430px] ssm:w-11/12 h-fit rounded-xl text-white bg-red  flex flex-row items-center justify-start ssm:justify-start z-[0]  ${src == '/asesoria-fiscal' ? 'px-1' : 'px-2'} py-2 mmd:px-1 text-left`}>
                    <div className="relative leading-[20px] pl-4 mmd:pl-7 ssm:pl-14">
                        <span className={`font-normal leading-[20px] font-text-sm-leading-5-font-normal ${src == '/asesoria-fiscal' ? 'text-xs' : 'text-sm'}   ssm:text-sm text-left`}>
                            <b>{textObject.textBold}</b> {textObject.text} 
                            {textObject.price !== undefined && <> ${textObject.price}</>}
                            
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BadgePromo;