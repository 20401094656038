import React from 'react';
import 'react-tooltip/dist/react-tooltip.css'
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

function Tooltip({ text, id }) {

    return (
        <div>
            <ReactTooltip
                anchorId={id}
                place="top"
                effect='solid'
                content={text}
                style={{ maxWidth: "20rem", zIndex: 10}} />
            <FontAwesomeIcon
                id={id}
                className="cursor-pointer text-gray-400 text-xl"
                icon={faCircleQuestion} />
        </div>
    )
}

Tooltip.propTypes = {
    text: PropTypes.string
}

export default Tooltip