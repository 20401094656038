import { apiKey } from '../config';
import { refresh } from './operations/sessionOperations';
import jwt_decode from "jwt-decode";
import cookie from "js-cookie";
import * as Sentry from "@sentry/nextjs";

const customFetch = async (
    url,
    method,
    params,
    req
) => {
    let body = undefined;
    if (params && method != "GET") {
        body = params;
        if (params.constructor == Object) {
            body = typeof params !== "string" ? JSON.stringify(params) : params;
        }
    }
    let options = {
        method,
        body,
        headers: {
            'x-api-key': apiKey,
            ...await authHeader(req)
        }
    };
    try{
        const response = await fetch(url, options);
        return handleResponse(response);
    } catch (e){
        return handleError(e);
    }
}

const get = (url, params, req) => {
    let response = customFetch(url, "GET", params, req);
    return response;
};

const post = (url, params, req) => {
    let response = customFetch(url, "POST", params, req);
    return response;
};

const put = (url, params, req) => {
    let response = customFetch(url, "PUT", params, req);
    return response;
};

const del = (url, params, req) => {
    let response = customFetch(url, "DELETE", params, req);
    return response;
};

const authHeader = async (req) => {
    let accessToken = cookie.get("access_token") || req?.cookies?.access_token;
    if (accessToken) {
        const { exp } = jwt_decode(accessToken);
        const expDate = new Date(0);
        expDate.setUTCSeconds(exp);
        if (new Date() > expDate) {
            let refreshToken = cookie.get("refresh_token") || req && req.cookies.refresh_token;
            cookie.remove("access_token");
            const response = await refresh(refreshToken);
            if (response.jwt){
                cookie.set("access_token", response.jwt.access_token, { secure: true })
                return { Authorization: `Bearer ${response.jwt.access_token}` };
            }else{
                return {};
            }
        } else {
            return { Authorization: `Bearer ${accessToken}` };
        }
    } else {
        return {};
    }
}

const handleResponse = (response) => {
    return response.text().then(text => {
        let data = text && JSON.parse(text);
        const jwt = response.headers.get('jwt');
        if (jwt) {
            data.jwt = JSON.parse(jwt);
        }
        if (!response.ok) {
            return handleError((data || {message: 'Error al procesar tu solicitud.'}), response.status);
        }

        return data;
    });
}

export const handleError = (error, statusCode) => {
    Sentry.captureException(new Error(error.message));
    let defaultError = statusCode == 503 ? 'Servicio no disponible, intenta nuevamente.' : 'Error al procesar tu solicitud.';
    let customError = new Error(error.code ? error.message : defaultError);
    customError.name = error.code || "badly_processed_request";
    customError.code = statusCode;
    return Promise.reject(customError);
}

const operations = {
    get,
    post,
    put,
    delete: del
}

export default operations