
  
  export const calculateTimeLeft = (endDate) => {
    const today = Math.floor(new Date().getTime() / 1000);
    const difference = endDate - today;

    let timeLeft = {};
  
    if (difference > 0 && endDate != null) {
      timeLeft = {
        days: Math.floor(difference / (60 * 60 * 24)),
        hours: Math.floor((difference / (60 * 60)) % 24),
        minutes: Math.floor((difference  / 60) % 60),
        seconds: Math.floor((difference) % 60),
      };
    }else{
      timeLeft = {
        days: 0,
        hours:0,
        minutes: 0,
        seconds: 0,
      };
    }
  
    return timeLeft;
  };
  