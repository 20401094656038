import React from 'react';
import Head from 'next/head'

function LayoutMeta({ title, description, url, image, alternateName, children }) {
    const structuredData = {
        "@context": "https://schema.org/",
        "@type": "Organization",
        "name": title,
        "url": url,
        "logo": "https://fixat.mx/_next/image?url=https%3A%2F%2Fcdn.fixat.mx%2Fassets%2Fimg%2Fno-webp%2Flogo-principal-fixat.png&w=1920&q=75",
        "alternateName": alternateName,
        "sameAs": ["https://www.facebook.com/Fixat-228162854300046/?fref=ts", "https://www.instagram.com/fixatoficial", "https://www.tiktok.com/@fixatoficial"]
    }
    return (
        <div>
            <Head>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta charSet="utf-8" />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
                />

                <meta property="og:url" content={url} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="fixat.mx" />
                <meta property="twitter:url" content={url} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description}/>
                <meta name="twitter:image" content={image} />
            </Head>
            <div className='overflow-hidden'>
                {children}
            </div>
        </div>
    )
}

export default LayoutMeta