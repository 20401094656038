import { useContext, useEffect, useState } from 'react';
import { calculateTimeLeft } from '../../util/calculateTime';
import { PromotionContext } from '../../pages/_app';

export const CounterBanner = () => {
  let { dateActive, loading, setLoading, setPromo } = useContext(PromotionContext);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(dateActive));
  useEffect(() => {
    if(dateActive != null && !loading){
        setTimeout(() => setTimeLeft(calculateTimeLeft(dateActive)), 1000);
        const today = Math.floor(new Date().getTime() / 1000);
        if(dateActive < today){
            setPromo(false)
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, dateActive]);

  useEffect(() => {
    if(dateActive != null){
        setTimeLeft(calculateTimeLeft(dateActive))
        setLoading(false)
    }else{
      setLoading(true)
    }
    return () => {
      setTimeLeft({});
    };    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateActive]);
  return (
    <div className={`flex gap-3 font-fredoka-one text-white`}>
      {timeLeft.days > 0 && <><div className="flex flex-col items-center">
        <span className="value">{String(timeLeft.days).padStart(2, '0')}</span>
        <span className="label font-quicksand">días</span>
      </div>
        <span>:</span></>}


      <div className="flex flex-col items-center">
        <span className="value">{String(timeLeft.hours).padStart(2, '0')}</span>
        <span className="label font-quicksand">horas</span>
      </div>
      <span>:</span>

      <div className="flex flex-col items-center">
        <span className="value">
          {String(timeLeft.minutes).padStart(2, '0')}
        </span>
        <span className="label font-quicksand">mins</span>
      </div>
      <span>:</span>

      <div className="flex flex-col items-center">
        <span className="value">
          {String(timeLeft.seconds).padStart(2, '0')}
        </span>
        <span className="label font-quicksand">segs</span>
      </div>
    </div>
  );
};