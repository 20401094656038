import React from 'react';
import Image from 'next/image';
import styles from '../../Styles/col.module.css'

export const Loader = ({ title = "Procesando...", description,  }) => {
    return (
        <div className="w-screen h-screen fixed top-0 left-0 loading_overlay flex justify-center flex-row items-center z-400 backdrop-filter backdrop-blur-lg">
            <div className="w-11/12 md:w-7/12 relative bg-transparent">
                <div className={`${styles['col-12']} mb-14 flex justify-center loading_image`}>
                    <Image 
                    src='https://cdn.fixat.mx/assets/img/no-webp/robot.png'
                    alt="Procesando..."
                    title="Procesando..."
                    height="175px"
                    width="139px"
                    priority={true}
                    />
                </div>
                <div className={`${styles['col-12']} justify-center`}>
                    <h2 className="mt-12 font-fredoka xs:text-3xl md:text-5xl text-center text-primary-dark">
                        {title}
                    </h2>
                    {description && 
                        <h3 className="font-fredoka xs:text-xl md:text-2xl text-center text-primary-dark px-32">
                            {description}
                        </h3>
                    }
                </div>
            </div>
        </div>
    )
}

export default Loader;