import apiClient, { handleError } from '../apiClient';
import { apiBaseUrl } from '../../config';
import cookie from "js-cookie";

export const refresh = async (refreshToken) => {
    return apiClient.post(`${apiBaseUrl}/sessions/refresh-token`, {
        refresh_token: refreshToken
    }).then(response => {
        return response;
    });
}

export const createSession = async (name, email, number, source, campaign_ads, req) => {
    return apiClient.post(`${apiBaseUrl}/sessions/lead`, {
        name: name,
        email: email,
        phone_number: number,
        source: source,
        campaign_ads: campaign_ads
    }, req).then(response => {
        if (!response.jwt){
            return handleError({message: 'Error al procesar tu solicitud.'});
        }
        cookie.set("access_token", response.jwt.access_token, { secure: true })
        cookie.set("refresh_token", response.jwt.refresh_token, { secure: true })
        return response;
    });
}

export const login = async (username, password, req) => {
    return apiClient.post(`${apiBaseUrl}/sessions/login`, {
        username: username,
        password: password
    }, req).then(response => {
        return response;
    });
}

export const adminLogin = async (name, email, phoneNumber, password, req) => {
    return apiClient.post(`${apiBaseUrl}/sessions/admin`, {
        name: name,
        email: email,
        phone_number: phoneNumber,
        password: password
    }, req).then(response => {
        return response;
    });
}

export const refreshAdmin = async (refreshToken) => {
    return apiClient.post(`${apiBaseUrl}/sessions/admin/refresh-token`, {
        refresh_token: refreshToken
    }).then(response => {
        return response;
    });
}

export const logout = () => {
    cookie.remove("access_token");
    cookie.remove("refresh_token");
}