import React, {useEffect} from 'react';
import { useRouter } from 'next/router';

function RouteLoader ({/*loading,*/ setLoading}) {

    const router = useRouter();
    useEffect(() => {
        const routeChangeComplete = () => {
            setLoading(false);
        }
        router.events.on('routeChangeComplete', routeChangeComplete)
        return () => {
            router.events.off('routeChangeComplete', routeChangeComplete)
        }
    }, [router.events, setLoading]);

    return (
        <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
        </div>
    )
}

export default RouteLoader
